import React from 'react';
import cx from 'classnames';
import { map, lowerCase, deburr } from 'lodash';

interface Option {
  link: string;
  name: string;
  category: string;
}

interface Options {
  options: Option[];
}

export const FooterItem = ({ options }: Options) => {
  const renderItems = map(options, (option: Option) => {
    const { link, name } = option;

    const urlStyle = cx(
      'ml0-ns mt4-ns flex flex-column no-underline white fw3 f14 ma12 ml24 lh-copy',
    );

    const url = link.split('=')[0];
    const vehicleType = link.split('=')[1];
    const normalizedVehicleType = vehicleType ? `=${lowerCase(deburr(vehicleType))}` : '';

    const newLink = `${url}${normalizedVehicleType}`;

    return (
      <a key={name} className={urlStyle} href={newLink} title={name} aria-label={name}>
        {name}
      </a>
    );
  });

  return <>{renderItems}</>;
};
