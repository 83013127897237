import { groupBy } from 'lodash';

export const treatMenu = (items: []) => {
  return groupBy(items, 'category');
};

export const treatContact = (items: []) => {
  const { true: socialNetwork, false: normalLink } = groupBy(items, 'social_network');

  return { socialNetwork, normalLink };
};
