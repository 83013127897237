import React from 'react';
import { Icon } from '../../../components/Icons';

export const FooterCopyright = () => {
  return (
    <>
      <div className="flex flex-column flex-row-ns justify-center-ns mt48 mh24 items-center">
        <Icon name="SvgIconLogoWhite" width={77} height={40} className="mb12 ma0-ns self-center" />
        <div className="ml24-ns">
          <p className="white ma0 f11 fw3 tc tl-ns lh-copy w-100">
            © 2000-2024, Expocar Veículos e Serviços Ltda. Todos os direitos reservados.
          </p>
          <p className="white ma0 f11 fw3 tc tl-ns lh-copy">
            Os anúncios colocados neste site são de responsabilidade de seus respectivos
            anunciantes, lojas e concessionárias.
          </p>
        </div>
      </div>
    </>
  );
};
