import React, { Component } from 'react';
import cx from 'classnames';
import { Icon } from '../../../components/Icons';
import styles from '../style.module.css';
import { FooterItem } from './FooterItem';

interface Props {
  title: string;
  options: Option[];
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface State {
  isOpen: boolean;
}

interface Option {
  link: string;
  name: string;
  category: string;
}

export class FooterSection extends Component<Props, State> {
  state = {
    isOpen: false,
  };

  handleClick = () => {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  };

  render() {
    const { title, options } = this.props;
    const { isOpen } = this.state;
    const itensStyle = cx('dib-ns', {
      dn: !isOpen,
      db: isOpen,
    });
    const arrowRotate = cx('mt12 dib dn-ns', {
      '': !isOpen,
      [styles.arrowRotate]: isOpen,
    });
    const titleStyle = cx(
      'white f14 ma0 mt12 mt0-ns fw6',
      {
        mb12: !isOpen,
        'mb4 mb12-ns': isOpen,
      },
      [styles.defaultCursor],
    );

    const containerRoot = cx('bb bb-0-ns mh24 mr0-ns br-ns h-100', styles.borderColor);

    const section = cx('flex justify-between pointer', styles.defaultCursor);

    return (
      <>
        <div className={containerRoot}>
          <div className={section} onClick={this.handleClick}>
            <p className={titleStyle}>{title}</p>
            <div className={arrowRotate}>
              <Icon
                name="SvgIconArrowDown"
                customId={`${title}footer`}
                width={18}
                height={18}
                className="white"
              />
            </div>
          </div>
          <div className={itensStyle}>
            <FooterItem options={options} />
          </div>
        </div>
      </>
    );
  }
}
