import React from 'react';

export const FooterPrivacyPolicy = () => {
  return (
    <div className="pt32 ph16 w-100 tc">
      <p className="f11 boulder ma0">
        Ao anunciar na Autoscar você concorda com a nossa
        <span className="ml4 underline"> Política de Privacidade </span> e
        <span className="ml4 underline"> Publicidade</span>, além de se responsabilizar por
        quaisquer informações anunciadas.
      </p>
    </div>
  );
};
