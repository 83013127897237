import React from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '../../components/Grid';
import { GridCell } from '../../components/GridCell';
import { FooterContact } from './components/FooterContact';
import { FooterCopyright } from './components/FooterCopyright';
import { FooterPrivacyPolicy } from './components/FooterPrivacyPolicy';
import { FooterSection } from './components/FooterSection';
import styles from './style.module.css';
import { treatMenu, treatContact } from './utils/treatFooterData';

interface Props {
  footerType?: string;
}

export const renderFooter = (data: any) => {
  const {
    allDirectusContato: { nodes: contact },
    allDirectusRodape: { nodes: menu },
  } = data;

  const { buy, sell, about_autoscar, needs_help } = treatMenu(menu);
  const treatedData = treatContact(contact);

  const footerClass = cx('noPrint', styles.backgroundColor);

  return (
    <footer className={footerClass}>
      <Grid className={styles.paddingSpaceCenter}>
        <GridCell width={[1, 1 / 4, 1 / 4, 1 / 5]} pl={[0, 0, 21, 0]}>
          <FooterSection title="Comprar" options={buy} />
        </GridCell>
        <GridCell width={[1, 1 / 4, 1 / 4, 1 / 5]}>
          <FooterSection title="Vender" options={sell} />
        </GridCell>
        <GridCell width={[1, '204px', 1 / 4, 1 / 5]}>
          <FooterSection title="Sobre a Autoscar" options={about_autoscar} />
        </GridCell>
        <GridCell width={[1, '178px', 1 / 4, 1 / 5]}>
          <FooterSection title="Precisa de ajuda?" options={needs_help} />
        </GridCell>
        <GridCell width={[1, 1, 1, 1 / 5]}>
          <FooterContact data={treatedData} />
        </GridCell>
        <GridCell>
          <FooterCopyright />
        </GridCell>
      </Grid>
    </footer>
  );
};

export const Footer = ({ footerType }: Props) => {
  if (footerType === 'privacyPolicy') {
    return <FooterPrivacyPolicy />;
  }

  const footerData = graphql`
    query {
      allDirectusRodape {
        nodes {
          link
          name
          category
        }
      }
      allDirectusContato {
        nodes {
          link
          social_network
          description
          icon
        }
      }
    }
  `;
  return <StaticQuery query={footerData} render={renderFooter} />;
};
