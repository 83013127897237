import React from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { Icon } from '../../../components/Icons';
import styles from '../style.module.css';

interface Props {
  data: any;
}

export const FooterContact = ({ data }: Props) => {
  const { socialNetwork, normalLink } = data;

  const getNormalLinks = () => {
    return map(normalLink, item => {
      const { icon, description } = item;
      const containerClass = cx('flex mt24 items-center', styles.removeFirstMargin, {
        [styles.widthDivAddress]: icon === 'SvgIconLocation',
      });
      return (
        <div className={containerClass} key={description}>
          <div>
            <Icon name={icon} width={21} className="white" customId="Footer" />
          </div>
          <p className="white ma0 fw3 f14 ml8 lh-title">{description}</p>
        </div>
      );
    });
  };

  const getSocialNetworks = () => {
    return map(socialNetwork, item => {
      const { icon, description, link } = item;
      return (
        <a
          target="_blank"
          href={link}
          className={styles.removeLastMargin}
          title={description}
          key={description} rel="noreferrer"
        >
          <Icon width={32} height={32} className="white mr16 mr24-ns" name={icon} />
        </a>
      );
    });
  };

  const containerRoot = cx(
    'w-100 flex-ns flex-row-ns flex-column-l items-center items-start-l ph24 mh0-ns mh0-m ml24-l ph48-ns ph0-m ph0-l justify-between',
    styles.spaceBorderContact,
  );

  const containerTitle = cx('white ma0 f14 fw6', styles.widthSocialMedia);

  return (
    <>
      <div className={containerRoot}>{getNormalLinks()}</div>
      <div className="flex items-center items-start-l mt32 mt24-l flex-column ml24-l">
        <p className={containerTitle}>Acompanhe nossas redes sociais</p>
        <div className="flex flex-row mt12 mt24-ns">{getSocialNetworks()}</div>
      </div>
    </>
  );
};
